import { register } from "../utils/serviceWorker";
import { checkVersion } from "../services";
import { watchVisibility } from "../utils/visibility";
import { initGA } from "../utils/tracking";
import { isSourceIntegration } from "../utils/browser";
import Router from "next/router";
import getConfig from "next/config";
import loadAppInsights from "../utils/applicationInsights";

if (process.browser) {
  const config = {
    onUpdate: (registration) => {
      const waitingServiceWorker = registration.waiting;
      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener("statechange", (event) => {
          if (event.target.state === "activated") {
            window.location.reload();
          }
        });
        waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
      }
    },
  };
  register(config);
  setInterval(() => {
    checkVersion();
  }, 10000);
  if (!isSourceIntegration()) {
    initGA("G-DRYEEGQDRD");
  }
  // Check whether website is currently being viewed or not
  watchVisibility();
  // Capturing page view on Route Change
  const { publicRuntimeConfig } = getConfig();
  Router.events.on("routeChangeStart", () => {
    if (publicRuntimeConfig.insights) {
      loadAppInsights.then((instance) => instance.trackPageView());
    }
  });
}
